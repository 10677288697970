<template>
  <div class="inspection-project-detail">
    <div class="detail-title">
      <img
        class="icon icon-back"
        @click="backToList"
        src="/img/project/icon-back.png"
        alt=""
      />
      {{ title }}
    </div>
    <div class="searchCon">
      <el-row>
        <el-col :span="11" :offset="1" class="title">巡检对象</el-col>
        <el-col :span="11" class="btns">
          <img
            class="img"
            @click="upload"
            src="/img/taskCenter/labelPage/上传.png"
          />
          <img
            class="img"
            @click="addFile"
            src="/img/taskCenter/labelPage/添加文件.png"
          />
        </el-col>
        <el-col :span="1">&emsp;</el-col>
        <el-col :span="22" :offset="1" class="searchIpt">
          <el-input placeholder="按巡检对象名称搜索" v-model="filterText">
          </el-input>
        </el-col>
        <el-col :span="1">&emsp;</el-col>
        <el-col :span="1">&emsp;</el-col>
        <!-- 分割线 -->
        <el-col :span="24" class="line"></el-col>
      </el-row>
    </div>
    <div class="container">
      <el-input
        class="showAppend-input"
        v-if="showAppend == true"
        size="mini"
        @input="changeInput"
        @blur="appendBlur"
        v-model="appendInput"
        :props="defaultProps"
        :placeholder="$t('input')"
        v-focus
      ></el-input>
      <div
        v-loading="treeLoading"
        element-loading-text="加载中，请稍后"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
        class="tree-box"
      >
        <el-tree
          ref="tree"
          :data="treeData"
          node-key="value"
          default-expand-all
          :expand-on-click-node="false"
          highlight-current
          :filter-node-method="filterNode"
          @node-click="nodeClick"
          @check="check"
        >
          <div
            @mouseleave="treeNodeMouseLeave(node, data)"
            class="tree-node"
            slot-scope="{ node, data }"
          >
            <span class="tree-node-icon">
              <img
                :width="16"
                v-if="data.type && data.type === NodeMap.OilWellPoint.DRAWTYPE"
                :src="'/img/map/oil-well.png'"
              />
              <img
                :width="16"
                v-else-if="
                  data.type && data.type === NodeMap.OilPipeline.DRAWTYPE
                "
                :src="'/img/map/oilLine.png'"
              />
              <img
                :width="16"
                v-else-if="
                  data.type && data.type === NodeMap.StorageTank.DRAWTYPE
                "
                :src="`/img/map/${NodeMap.StorageTank.DRAWTYPE}.png`"
              />
              <img
                :width="16"
                v-else-if="
                  data.type && data.type === NodeMap.MeasurementStation.DRAWTYPE
                "
                :src="`/img/map/${NodeMap.MeasurementStation.DRAWTYPE}.png`"
              />
              <img
                :width="16"
                v-else-if="
                  data.type && data.type === NodeMap.StationStorage.DRAWTYPE
                "
                :src="`/img/map/${NodeMap.StationStorage.DRAWTYPE}.png`"
              />
              <img
                :width="16"
                v-else-if="
                  data.type && data.type === NodeMap.PowerEquipment.DRAWTYPE
                "
                :src="`/img/map/${NodeMap.PowerEquipment.DRAWTYPE}.png`"
              />
              <img
                :width="16"
                v-else-if="data.type && data.type === NodeMap.OilRoad.DRAWTYPE"
                :src="`/img/map/${NodeMap.OilRoad.DRAWTYPE}.png`"
              />
              <img
                :width="16"
                v-else-if="
                  data.type && data.type === NodeMap.PowerLine.DRAWTYPE
                "
                :src="`/img/map/${NodeMap.PowerLine.DRAWTYPE}.png`"
              />
              <img
                :width="16"
                v-else-if="
                  data.type && data.type === NodeMap.GasWell.DRAWTYPE
                "
                :src="`/img/map/${NodeMap.GasWell.DRAWTYPE}.png`"
              />
              <i v-else class="el-icon-folder"></i>
            </span>
            <span class="tree-node-text">{{ node.label }}</span>
            <span class="tree-node-btn">
              <i
                v-if="node.level === 1 && data.type !== 0"
                @click.prevent="
                  (e) => {
                    editNode(node, data, e);
                  }
                "
                class="el-icon-edit-outline"
              ></i>
              <i
                v-if="node.level !== 1"
                @click.prevent="
                  (e) => {
                    flyToNode(node, data, e);
                  }
                "
                class="el-icon-aim"
              ></i>
              <i
                v-if="data.type !== 0"
                @click.prevent="
                  (e) => {
                    deleteTreeNode(node, data, e);
                  }
                "
                :class="data.value === deleteNodeValue ? 'bgc-red' : ''"
                class="el-icon-delete"
              ></i>
              <i
                @click.prevent="
                  (e) => {
                    showHiddenNode(node, data, e);
                  }
                "
                :class="data.show ? 'active-icon' : ''"
                class="el-icon-view"
              >
              </i>
            </span>
          </div>
        </el-tree>
      </div>
    </div>
    <el-dialog
      :title="$t('inspection.uploadInspection')"
      :visible.sync="inspectionDialogVisible"
      width="30%"
      append-to-body
      :close-on-click-modal="false"
      :before-close="handleClose"
    >
      <span>
        <el-form
          :model="form"
          ref="form"
          :rules="rules"
          label-width="120px"
          class="demo-dynamic"
        >
          <el-form-item prop="coordinate" :label="$t('map.coordinateSystem')">
            <el-input
              style="width: 200px"
              :disabled="true"
              v-model="form.coordinate"
            ></el-input>
          </el-form-item>
          <el-form-item prop="type" :label="$t('inspection.type')">
            <el-select
              style="width: 200px"
              v-model="form.type"
              :placeholder="$t('inspection.type')">
              <el-option
                v-for="item in uploadList"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            prop="possessionUnit"
            :label="$t('inspection.possessionUnit')">
            <select-tree
              :data="deptList"
              style="width: 150px; margin-left: 5px"
              size="small"
              :placeholder="$t('selectPlease')"
              v-model="form.possessionUnit">
            </select-tree>
          </el-form-item>
          <el-form-item prop="type" label="">
            <el-upload
              :multiple="false"
              class="upload-demo"
              :headers="uploadHeaders"
              ref="upload"
              name="file"
              :http-request="httpRequest"
              :on-remove="handleRemove"
              :file-list="fileList"
              :limit="1"
              :auto-upload="false">
              <el-button slot="trigger" size="small" type="primary">{{
                $t("uploadText")
              }}</el-button>
              <div slot="tip" class="el-upload__tip">
                {{ $t("inspection.uploadKML") }}
              </div>
            </el-upload>
          </el-form-item>
        </el-form>
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="inspectionDialogVisible = false">{{
          $t("cancelText")
        }}</el-button>
        <el-button type="primary" @click="uploadKML">{{
          $t("submitText")
        }}</el-button>
      </span>
    </el-dialog>
    <el-dialog
      :title="$t('inspection.uploadInspection')"
      :visible.sync="editDialogVisible"
      width="30%"
      append-to-body
      :close-on-click-modal="false"
      :before-close="editClose"
    >
      <span>
        <el-form
          :model="pathForm"
          ref="pathForm"
          label-width="120px"
          class="demo-dynamic"
        >
          <el-form-item prop="coordinate" :label="`${$t('folderName')}:`">
            <el-input v-model="pathForm.dirName"></el-input>
          </el-form-item>
        </el-form>
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editClose">{{ $t("cancelText") }}</el-button>
        <el-button type="primary" :loading="saveLoading" @click="saveEdit">{{
          $t("submitText")
        }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getProjectDetail } from "@/api/project/index";
import {
  addTreeNode,
  addGroup,
  addLabel,
  setExpand,
  deleteTreeNode,
  getLabelTreeList,
  updateTreeNode,
  updateDirVisibility
} from "@/api/project/label";
import { getList } from "@/api/system/dept";
import axios from "axios";
import website from "@/config/website";
import { Base64 } from "js-base64";
import { mapGetters } from "vuex";
import NodeMap from "@/components/cesium/js/node/index";

export default {
  name: "OilGas-inspectionPage",
  data() {
    return {
      NodeMap,
      // 该项目的具体信息
      detailInfo: {},
      // 搜索内容
      filterText: "",
      // 增加文件名称
      appendInput: "",
      groupCount: 0,
      showAppend: false,
      treeData: [],
      defaultProps: {
        children: "children",
        label: "label",
      },
      checked: [],
      // 双击事件
      nodeItem: {},
      isEdit: 0,
      nodeCount: 0,
      preNodeId: null,
      curNodeId: null,
      nodeTimer: null,
      newApiGroupName: "",
      deleteNodeValue: null,
      treeLoading: true,
      selecteId: null,
      inspectionDialogVisible: false,
      uploadList: [
        {
          label: this.$t(`inspection.${NodeMap.OilWellPoint.DRAWTYPE}`),
          value: NodeMap.OilWellPoint.DRAWTYPE,
        },
        {
          label: this.$t(`inspection.${NodeMap.OilPipeline.DRAWTYPE}`),
          value: NodeMap.OilPipeline.DRAWTYPE,
        },
        {
          label: this.$t(`inspection.${NodeMap.StorageTank.DRAWTYPE}`),
          value: NodeMap.StorageTank.DRAWTYPE,
        },
        {
          label: this.$t(`inspection.${NodeMap.MeasurementStation.DRAWTYPE}`),
          value: NodeMap.MeasurementStation.DRAWTYPE,
        },
        {
          label: this.$t(`inspection.${NodeMap.StationStorage.DRAWTYPE}`),
          value: NodeMap.StationStorage.DRAWTYPE,
        },
        {
          label: this.$t(`inspection.${NodeMap.OilRoad.DRAWTYPE}`),
          value: NodeMap.OilRoad.DRAWTYPE,
        },
        {
          label: this.$t(`inspection.${NodeMap.PowerLine.DRAWTYPE}`),
          value: NodeMap.PowerLine.DRAWTYPE,
        },
        {
          label: this.$t(`inspection.${NodeMap.PowerEquipment.DRAWTYPE}`),
          value: NodeMap.PowerEquipment.DRAWTYPE,
        },
        {
          label: this.$t(`inspection.${NodeMap.GasWell.DRAWTYPE}`),
          value: NodeMap.GasWell.DRAWTYPE,
        },
      ],
      // 属地单位列表
      deptList: [],
      form: {
        coordinate: "WGS84",
        type: NodeMap.OilWellPoint.DRAWTYPE,
        possessionUnit: "",
      },
      rules: {
        coordinate: [
          { required: true, message: this.$t("inputPlease"), trigger: "blur" },
        ],
        type: [
          {
            required: true,
            message: this.$t("selectPlease"),
            trigger: "change",
          },
        ],
        possessionUnit: [
          {
            required: true,
            message: this.$t("selectPlease"),
            trigger: "change",
          },
        ],
      },
      uploadHeaders: {},
      loading: false,
      editDialogVisible: false,
      pathForm: {
        id: "",
        dirName: "",
      },
      saveLoading: false,
    };
  },
  directives: {
    //注册一个局部的自定义指令 v-focus
    focus: {
      // 指令的定义
      inserted: function (el) {
        // 聚焦元素
        el.querySelector("input").focus();
      },
    },
  },
  computed: {
    ...mapGetters(["newFeature"]),
    title() {
      if (this.$route.query && this.$route.query.projectName) {
        return this.$route.query.projectName;
      }
      if (this.detailInfo && this.detailInfo.projectName) {
        return this.detailInfo.projectName;
      }
      return "";
    },
  },
  created() {
    this.getDetail();
  },
  mounted() {
    this.getDeptTree();
    this.getList(true);
    this.$EventBus.$off("left-tree-update");
    this.$EventBus.$on("left-tree-update", this.getList);
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    },
    newFeature: function (val) {
      if (val) {
        this.addNewLabel(val);
      }
    },
  },
  methods: {
    backToList() {
      this.$router.push("/oil-gas/OG-Inspection");
    },

    getDetail() {
      let project = this.$route.query.projectId;
      getProjectDetail({ id: project }).then((res) => {
        if (res.data.code === 200) {
          this.detailInfo = res.data.data;
        }
      });
    },

    getList(isInit) {
      let projectId = this.$route.query.projectId;
      if (!projectId) {
        return;
      }
      const params = {
        projectId,
      };
      getLabelTreeList(params)
        .then((res) => {
          if (res.data.code === 200) {
            let data = res.data.data;
            this.treeData = data.map((item, index) => {
              item.label = item.dirName;
              item.value = item.id;
              item.index = index;
              item.show = item.visibility;
              item.children = item.mapMarks.map((_item) => {
                return {
                  label: _item.content.properties.name,
                  value: _item.id,
                  show: _item.visibility,
                  type: _item.objectType,
                };
              });
              item.show = item.children.some((item) => {
                return item.show !== false;
              });
              return item;
            });
            this.groupCount = data.length;
            // this.$store.dispatch("setLabels", this.treeData);
            if (isInit && Array.isArray(data) && data.length > 0) {
              // 初次加载设置默认选中
              this.$nextTick(() => {
                this.$refs.tree.setCurrentKey(this.treeData[0].id);
              });
              // 维护全局变量信息
              this.$store.dispatch("setHandlerType", {
                type: "switchWorkSpace",
                params: {
                  groupId: this.treeData[0].id,
                  groupName: this.treeData[0].label,
                  visible: this.treeData[0].visibility,
                },
              });
            } else {
              if (this.selecteId) {
                this.$nextTick(() => {
                  var key = this.$refs.tree.getCurrentKey(this.selecteId);
                  if (key) {
                    this.$refs.tree.setCurrentKey(key);
                  } else {
                    this.$refs.tree.setCurrentKey(this.treeData[0].id);
                  }
                });
              }
            }
          }
        })
        .finally(() => {
          this.treeLoading = false;
        });
    },

    nodeClick(data, node, prop) {
      // 记录当前选中的树节点
      this.selecteId = node.data.value;
      if (node.level === 1) {
        this.$store.dispatch("setHandlerType", {
          type: "switchWorkSpace",
          params: {
            groupId: data.id,
            groupName: data.label,
            visible: prop.node.checked,
          },
        });
      } else {
        let _node = this.findNodeByLevel(node, 1);
        this.$store.dispatch("setHandlerType", {
          type: "switchWorkSpace",
          params: {
            groupId: _node.data.id,
            groupName: _node.data.label,
            visible: _node.visibility,
          },
        });
        if (node.level === 2) {
          if (data.show) {
            this.$EventBus.$emit("select-inspection-by-id", data.value);
          }
        }
      }
    },

    findNodeByLevel(node, level) {
      if (!node || node.level < level) {
        return null;
      }
      if (node.level === level) {
        return node;
      } else {
        return this.findNodeByLevel(node.parent, level);
      }
    },

    addNewLabel(val) {
      addLabel({
        elementType: val.type,
        gisJson: val.geojson,
        groupId: val.groupId,
        name: val.label,
      }).then((res) => {
        const newID = res.data.data.id;
        const keys = this.$refs.tree.getCheckedKeys();
        keys.push(newID);
        const params = {
          workSpaceId: this.$store.state.common.editProject.id,
          checkedKeys: keys,
        };
        setExpand(params).then((res) => {
          this.getList();
        });
      });
      this.$store.dispatch("setNewFeature", null); // 清空
    },

    filterNode(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },

    download() {
      console.log("下载");
    },

    upload() {
      this.inspectionDialogVisible = true;
    },

    addFile() {
      this.showAppend = true;
      this.appendInput = `新建文件夹${this.groupCount + 1}`;
    },

    changeInput(n) {
      this.appendInput = n;
    },

    appendBlur() {
      let projectId = this.$route.query.projectId;
      const data = {
        projectId,
        dirName: this.appendInput,
        type: 1,
      };
      addTreeNode(data).then((res) => {
        if (res.data.code === 200) {
          this.getList();
          this.$EventBus.$emit("add-inspection-work-space", res.data.data);
          this.showAppend = false;
        }
      });
    },

    check(a, b, c) {
      const params = {
        workSpaceId: this.$store.state.common.editProject.id,
        checkedKeys: b.checkedKeys,
      };

      setExpand(params).then((res) => {
        if (res.data.code === 200) {
          this.$nextTick(function () {
            this.$refs.tree.setCheckedKeys(b.checkedKeys);
          });
        }
      });
    },

    changeEdit(n) {
      this.newApiGroupName = n;
    },

    submitEdit(node, data) {
      if (data.label == this.newApiGroupName) {
        this.newApiGroupName = "";
        this.isEdit = 0;
        this.nodeItem = {};
      } else {
        this.updateApiGroup(data);
      }
    },

    updateApiGroup(data) {
      const params = {
        id: data.id,
        name: this.newApiGroupName,
        workSpaceId: this.$store.state.common.editProject.id,
      };
      addGroup(params).then((res) => {
        if (res.data.code === 200) {
          this.newApiGroupName = "";
          this.isEdit = 0;
          this.nodeItem = {};
          this.getList();
        }
      });
    },

    treeNodeMouseLeave(node, data) {
      this.deleteNodeValue = null;
    },

    deleteTreeNode(node, data, e) {
      e.stopPropagation();
      if (this.deleteNodeValue === null) {
        this.deleteNodeValue = data.value;
      } else {
        if (node.level === 1) {
          // 删除路径
          deleteTreeNode({
            id: data.id,
          }).then(() => {
            this.getList();
            this.$EventBus.$emit("delete-inspection-work-space", data);
          });
        } else {
          this.treeLoading = true;
          this.$EventBus.$emit("delete-inspection-by-id", data.value, () => {
            this.getList();
          });
        }
        this.deleteNodeValue = null;
      }
    },

    showHiddenNode(node, data, e) {
      e.stopPropagation();
      if (node.level === 1) {
        let flag = !this.isActive(data);
        let ids = [];
        data.show = flag;
        for (let i = 0; i < data.children.length; i++) {
          let item = data.children[i];
          item.show = flag;
          ids.push(item.value);
        }
        ids = ids.join(",");
        updateDirVisibility({
          id: data.id,
          visibility: flag? 1: 0
        })
        this.$EventBus.$emit("visibility-inspection-nodes", ids, flag, false);
      } else {
        let flag = !data.show;
        data.show = flag;
        this.$EventBus.$emit("visibility-inspection-nodes", data.value, flag, true);
        let _node = this.findNodeByLevel(node, 1);
        _node.data.show = this.isActive(_node.data);
      }
    },

    flyToNode(node, data, e) {
      data.show = true;
      e.stopPropagation();
      this.$EventBus.$emit("visibility-inspection-nodes", data.value, true);
      window.setTimeout(() => {
        this.$EventBus.$emit("fly-to-by-id", data.value);
        this.$EventBus.$emit("select-inspection-by-id", data.value);
      }, 200);
    },

    isActive(data) {
      return data.children.some((item) => {
        return item.show !== false;
      });
    },

    handleClose() {
      this.inspectionDialogVisible = false;
      this.$refs.upload.clearFiles();
      this.$refs.form.resetFields();
    },

    uploadKML() {
      this.$refs.form.validate((res) => {
        if (res) {
          this.loading = true;
          this.$refs.upload.submit();
          setTimeout(() => {
            this.handleClose();
          }, 0);
        }
      });
    },

    getDeptTree() {
      getList().then((result) => {
        if (result.data.code === 200) {
          let data = result.data.data;
          this.deptList = this.filterDeptTree(data);
        }
      });
    },

    filterDeptTree(tree) {
      return tree.map((item) => {
        item.label = item.deptName;
        item.value = item.id;
        if (item.children && Array.isArray(item.children)) {
          item.children = this.filterDeptTree(item.children);
        }
        return item;
      });
    },

    httpRequest(node) {
      let projectId = this.$route.query.projectId;
      let formData = new FormData();
      let possessionUnit = this.form.possessionUnit;
      formData.append("file", node.file);
      let url = ``;
      switch (this.form.type) {
        case NodeMap.OilWellPoint.DRAWTYPE:
          url = `/api/aos-gisservice/oilwellpoint/importPoint?projectId=${projectId}&deptId=${possessionUnit}`;
          break;
        case NodeMap.OilPipeline.DRAWTYPE:
          url = `/api/aos-gisservice/oilline/importLine?projectId=${projectId}&deptId=${possessionUnit}`;
          break;
        case NodeMap.StorageTank.DRAWTYPE:
          url = `/api/aos-gisservice/oilstoragetank/importStorageTank?projectId=${projectId}&deptId=${possessionUnit}`;
          break;
        case NodeMap.MeasurementStation.DRAWTYPE:
          url = `/api/aos-gisservice/oilmeasurementstation/importMeasurementStation?projectId=${projectId}&deptId=${possessionUnit}`;
          break;
        case NodeMap.StationStorage.DRAWTYPE:
          url = `/api/aos-gisservice/oilstationstorage/importStationStorage?projectId=${projectId}&deptId=${possessionUnit}`;
          break;
        case NodeMap.OilRoad.DRAWTYPE:
          url = `/api/aos-gisservice/oilroad/importRoad?projectId=${projectId}&deptId=${possessionUnit}`;
          break;
        case NodeMap.PowerLine.DRAWTYPE:
          url = `/api/aos-gisservice/oilpowerline/importPowerLine?projectId=${projectId}&deptId=${possessionUnit}`;
          break;
        case NodeMap.PowerEquipment.DRAWTYPE:
          url = `/api/aos-gisservice/oilpowerequipment/importPowerEquipment?projectId=${projectId}&deptId=${possessionUnit}`;
          break;
        case NodeMap.GasWell.DRAWTYPE:
          url = `/api/aos-gisservice/oilgaswellpoint/importPoint?projectId=${projectId}&deptId=${possessionUnit}`;
          break;
        default:
          return;
      }
      axios({
        baseURL: "",
        url,
        method: "post",
        data: formData,
        headers: {
          "Content-Type":
            "multipart/form-data; boundary=----WebKitFormBoundaryn8D9asOnAnEU4Js0",
          Authorization: `Basic ${Base64.encode(
            `${website.clientId}:${website.clientSecret}`
          )}`,
        },
      }).then((res) => {
          const data = res.data;
          if (data.code === 200) {
            this.getList();
            this.$EventBus.$emit("update-inspection");
            this.$message.success(data.msg);
          } else {
            this.$message.error(data.msg);
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },

    handleRemove(file) {
      console.log(file);
    },

    editNode(node, data, e) {
      e.stopPropagation();
      this.pathForm = {
        id: data.id,
        dirName: data.dirName,
      };
      this.editDialogVisible = true;
    },

    editClose() {
      this.$refs.pathForm.resetFields();
      this.editDialogVisible = false;
    },

    saveEdit() {
      this.saveLoading = true;
      updateTreeNode(this.pathForm)
        .then((res) => {
          let data = res.data;
          if (data.code === 200) {
            this.getList();
            this.editClose();
            this.$message.success(data.msg);
          } else {
            this.$message.error(data.msg);
          }
        })
        .finally(() => {
          this.saveLoading = false;
        });
    },
  }
};
</script>

<style lang="scss" scoped>
.inspection-project-detail {
  display: flex;
  flex-direction: column;
  position: relative;
  .detail-title {
    position: absolute;
    top: -49px;
    left: -56px;
    font-size: 14px;
    font-family: MicrosoftYaHeiUI-Bold, MicrosoftYaHeiUI;
    font-weight: bold;
    color: #ffffff;
    padding-top: 9px;
    padding-bottom: 11px;
    display: flex;
    align-items: center;
    .icon-back {
      margin-right: 4px;
    }
  }
  .searchCon {
    .title {
      font-size: 14px;
      font-weight: bold;
      padding-top: 18px;
      color: #fff;
    }

    .btns {
      padding-top: 18px;
      text-align: right;
      .img {
        width: 17px;
        height: 14px;
        margin-left: 10px;
        cursor: pointer;
      }
    }

    .searchBtn {
      text-align: right;
      .el-button {
        border: #265edd;
        background: #265edd;
        height: 50%;
      }
    }

    .line {
      margin: 0;
      padding: 0;
      height: 1px;
      background-color: #030303;
    }

    ::v-deep .el-input__inner {
      background-color: #000000;
      border: 1px solid #535353;
      color: #ffffff;
    }

    ::v-deep input::-webkit-input-placeholder {
      -webkit-text-fill-color: #535353;
    }
  }
  .container {
    flex-grow: 1;
    .showAppend-input {
      margin-bottom: 4px;
    }
    margin-top: 12px;
    width: 100%;
    box-sizing: border-box;
    background-color: #1e222a;
    color: #eee;
    max-height: calc(100% - 140px);
    overflow-y: overlay;
    overflow-x: hidden;
    .line {
      margin: 0;
      padding: 0;
      height: 1px;
      background-color: #030303;
    }

    .tree-box {
      font-size: 14px;
      height: 100%;
      //一级节点不显示复选框 其他子节点，收起状态不显示复选框，展开状态显示复选框，叶子节点显示复选框

      ::v-deep .el-tree > .el-tree-node > .el-tree-node__content .el-checkbox {
        // display: none;
        margin-bottom: 2.5px;
      }
      .el-tree {
        background: transparent;
        color: #fff;
        ::v-deep .el-tree-node {
          //叶子节点显示复选框，其他节点不显示复选框
          .is-leaf ~ .el-checkbox .el-checkbox__inner {
            display: flex;
            margin-bottom: 2.5px;
          }
          .el-checkbox__inner {
            background: transparent;
            border: 1px solid #535353;
          }

          .el-checkbox__input.is-checked .el-checkbox__inner,
          .el-checkbox__input.is-indeterminate .el-checkbox__inner {
            background: #333333dd;
          }
        }
        ::v-deep .el-tree-node__content:hover {
          background-color: #333333dd;
          color: #fff;
        }

        ::v-deep .el-tree-node:focus > .el-tree-node__content {
          background: transparent;
          color: #fff;
        }

        ::v-deep .el-tree-node:focus {
          background: transparent !important;
          color: #fff;
        }

        ::v-deep .el-tree-node.is-current > .el-tree-node__content {
          background-color: #333333dd;
          color: #fff;
        }

        ::v-deep .el-table tbody tr {
          pointer-events: none;
        }
        ::v-deep
          .el-tree--highlight-current
          .el-tree-node.is-current
          > .el-tree-node__content {
          color: #fff;
        }
        .el-button {
          color: #fff;
          font-size: 14px;
        }
      }
      .custom-tree-node {
        ::v-deep .el-input__inner {
          background-color: transparent;
          border: none;
          height: 100%;
          color: #fff;
        }
        width: 100%;
        .treeCon {
          width: 100%;
          .treeLeft {
            margin: 0;
          }
          .treeRight {
            margin: 0;
            text-align: end;
          }
        }
      }
      .tree-node {
        clear: both;
        flex-grow: 1;
        .tree-node-icon {
          float: left;
          margin-right: 5px;
        }
        .tree-node-text {
          max-width: 155px;
          float: left;
          overflow: hidden;
          white-space: nowrap; /* 防止文字换行 */
          text-overflow: ellipsis; /* 超出部分显示省略号 */
        }
        .tree-node-btn {
          float: right;
          padding-right: 8px;
          i {
            margin-left: 4px;
          }
        }
      }
      .active-icon {
        color: #265edd;
      }
    }

    .append {
      ::v-deep .el-input__inner {
        background-color: transparent;
        border: none;
        height: 100%;
        color: #fff;
      }
    }
  }
}
.bgc-red {
  background: red;
}
.bgc-red:hover {
  background: red !important;
}
</style>
